var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "showcase-intro" }, [
    _c("h1", { staticClass: "title" }, [
      _vm._v("\n    Global Brand Showcase\n  "),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "subtitle" },
      [
        _c("h4", [
          _vm._v(
            "\n      See the best Salesforce brand work from your teams and colleagues around the globe.\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      Have you and your team created something amazing? Here’s you chance to show it off.\n    "
          ),
        ]),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "btn primary",
            attrs: {
              to: _vm.$generateRoute({
                name: "showcase.submit",
                params: {
                  showcaseId: _vm.showcaseId,
                },
              }),
            },
          },
          [_vm._v("\n      Submit here\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }