var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "showcase-links" },
    [
      _vm._l(_vm.links, function (link, index) {
        return _c("div", { key: index, staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("TextInput", {
                ref: "link_label",
                refInFor: true,
                attrs: { model: _vm.links[index].text, label: "Link label" },
                on: {
                  "update:model": function ($event) {
                    return _vm.$set(_vm.links[index], "text", $event)
                  },
                },
              }),
              _vm._v(" "),
              _vm.validation[index] !== undefined && !_vm.validation[index].text
                ? _c("div", { staticClass: "form-input" }, [
                    _c("div", { staticClass: "error" }, [
                      _vm._v("\n          This field is required\n        "),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("TextInput", {
                ref: "url",
                refInFor: true,
                attrs: { model: _vm.links[index].url, label: "URL" },
                on: {
                  "update:model": function ($event) {
                    return _vm.$set(_vm.links[index], "url", $event)
                  },
                },
              }),
              _vm._v(" "),
              _vm.validation[index] !== undefined && !_vm.validation[index].url
                ? _c("div", { staticClass: "form-input" }, [
                    _c("div", { staticClass: "error" }, [
                      _vm._v("\n          Wrong URL format\n        "),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col align-self-center" },
            [
              _vm.links.length > 1
                ? _c("showcase-button", {
                    attrs: { type: "remove", "button-text": "Remove link" },
                    on: {
                      clickEvent: function ($event) {
                        return _vm.removeLink(index)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ])
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "add-row" },
        [
          _c("showcase-button", {
            attrs: { type: "add", "button-text": "Add another link" },
            on: { clickEvent: _vm.createLink },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }