var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.submission
    ? _c("div", { staticClass: "subpage-layout submission" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v("\n    Global Brand Showcase\n  "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _vm.showcase
              ? _c("back-button", {
                  staticClass: "back-btn",
                  attrs: {
                    "showcase-id": _vm.showcaseId,
                    "button-text": "Back to " + _vm.showcase.name,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _vm.showcase
          ? _c(
              "div",
              { staticClass: "submission-content" },
              [
                _vm.isPublished
                  ? _c("ShowcaseNavigationButtons", {
                      staticClass: "show-mobile",
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "flex-desktop" }, [
                  _c("h2", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.submission && _vm.submission.title) +
                        "\n      "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "showcase-actions" },
                  [
                    _vm.isPublished
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "action-item like",
                              attrs: { tabindex: "0" },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleLike(_vm.submissionId)
                                },
                              },
                            },
                            [
                              !_vm.isLiked(_vm.submissionId)
                                ? _c("icon", {
                                    attrs: { id: "ic_favorite_border" },
                                  })
                                : _c("icon", { attrs: { id: "ic_favorite" } }),
                              _vm._v(" "),
                              !_vm.isLiked(_vm.submissionId)
                                ? _c("span", { staticClass: "label" }, [
                                    _vm._v("Like"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isLiked(_vm.submissionId)
                                ? _c("span", { staticClass: "label" }, [
                                    _vm._v("Unlike"),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "action-item share" }, [
                            _c(
                              "a",
                              { attrs: { href: _vm.mailToLink } },
                              [
                                _c("icon", { attrs: { id: "ic_share" } }),
                                _vm._v(" "),
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("Share"),
                                ]),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("ShowcaseNavigationButtons", {
                            staticClass: "hide-mobile",
                          }),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.sliderData
                  ? _c(
                      "div",
                      [
                        _c("ShowcaseSlider", {
                          staticClass: "mb-lg",
                          attrs: {
                            id: "showcase_submission_slider",
                            title: false,
                            slides: _vm.sliderData,
                            "slideshow-delay": "7000",
                            "show-controls": _vm.sliderData.length > 1,
                            "show-indicators": _vm.sliderData.length > 1,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "row row_25_25_25_25 details-block hide-mobile",
                  },
                  [
                    _c("div", { staticClass: "col" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("p", { staticClass: "caption" }, [
                        _vm._v(
                          "\n          " + _vm._s(_vm.author) + "\n        "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c("p", { staticClass: "caption" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.submission.business) +
                            "\n        "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._m(2),
                      _vm._v(" "),
                      _c("p", { staticClass: "caption" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.submission.region) +
                            "\n        "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _vm._m(3),
                      _vm._v(" "),
                      _c("p", { staticClass: "caption" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.submission.country) +
                            "\n        "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _vm._m(4),
                        _vm._v(" "),
                        _vm._l(_vm.teamMembers, function (teamMember, index) {
                          return _c(
                            "p",
                            { key: index, staticClass: "caption" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(teamMember.firstName) +
                                  " " +
                                  _vm._s(teamMember.lastName) +
                                  " - " +
                                  _vm._s(teamMember.projectRole) +
                                  "\n        "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _vm._m(5),
                        _vm._v(" "),
                        _vm._l(_vm.businessProduct, function (item, index) {
                          return _c(
                            "p",
                            { key: index, staticClass: "caption" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(item.label) +
                                  "\n        "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _vm._m(6),
                        _vm._v(" "),
                        _vm._l(_vm.targetAudience, function (item, index) {
                          return _c(
                            "p",
                            { key: index, staticClass: "caption" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(item.label) +
                                  "\n        "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row row_75_25" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("div", [
                      _c("h5", [_vm._v("Project synopsis")]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_vm.submission.description))]),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("h5", [_vm._v("Project insights")]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_vm.submissionData.insights))]),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("h5", [_vm._v("Start date")]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.submissionData.startMonth) +
                            ", " +
                            _vm._s(_vm.submissionData.startYear)
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.links.length > 0
                      ? _c("div", [
                          _c("h5", [
                            _vm._v("Results, learnings, and related links"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "list-unstyled mt-0" },
                            _vm._l(_vm.links, function (link, index) {
                              return _c("li", { key: index }, [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: link.url, target: "_blank" },
                                  },
                                  [_vm._v(_vm._s(link.text))]
                                ),
                              ])
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", [
                      _c("h5", [_vm._v("Project brief")]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "list-unstyled mt-0" },
                        _vm._l(_vm.briefFiles, function (file, index) {
                          return _c("li", { key: index }, [
                            _c(
                              "a",
                              { attrs: { href: file.src, target: "_blank" } },
                              [_vm._v(_vm._s(file.name))]
                            ),
                            _vm._v(" "),
                            _c("img", {
                              attrs: { src: file.thumbnail, alt: file.name },
                            }),
                          ])
                        }),
                        0
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false",
                          },
                        ],
                      },
                      [
                        _c("h5", [
                          _vm._v(
                            "\n            Comments (" +
                              _vm._s(_vm.comments.length) +
                              ")\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.onCommentSubmit.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c("TextareaInput", {
                              ref: "comment",
                              attrs: {
                                placeholder: "Type your comment here...",
                                model: _vm.comment,
                                required: "true",
                                validate: "true",
                                rows: 10,
                              },
                              on: {
                                "update:model": function ($event) {
                                  _vm.comment = $event
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn primary link-button",
                                attrs: {
                                  type: "submit",
                                  title: "Submit Content",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Submit Comment\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false",
                      },
                    ],
                    staticClass: "comment-section",
                  },
                  _vm._l(_vm.comments, function (item, index) {
                    return _c("div", { key: index, staticClass: "comment" }, [
                      _c("div", { staticClass: "meta" }, [
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.commenter) +
                              " | " +
                              _vm._s(item.date) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._isAdminUser
                          ? _c(
                              "a",
                              {
                                staticClass: "action-item",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.editComment(item)
                                  },
                                },
                              },
                              [_vm._v("Edit")]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(item.message))]),
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.isPublished
                  ? _c(
                      "div",
                      { staticClass: "submission-navigation" },
                      [
                        _c("ShowcaseNavigationButtons", {
                          on: { navClick: _vm.onNavClick },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "caption" }, [
      _c("strong", [_vm._v("Author")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "caption" }, [
      _c("strong", [_vm._v("Line of business")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "caption" }, [
      _c("strong", [_vm._v("Region")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "caption" }, [
      _c("strong", [_vm._v("Country")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "caption" }, [
      _c("strong", [_vm._v("Collaborators")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "caption" }, [
      _c("strong", [_vm._v("Business/Product")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "caption" }, [
      _c("strong", [_vm._v("Target audience")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }