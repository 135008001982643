var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "showcase-submission-form" },
    [
      _c("h1", { staticClass: "title" }, [
        _vm._v("\n    Global Brand Showcase\n  "),
      ]),
      _vm._v(" "),
      _vm.showcase
        ? _c("back-button", {
            staticClass: "back-btn",
            attrs: {
              "showcase-id": _vm.showcaseId,
              "button-text": "Back to " + _vm.showcase.name,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("h2", { staticClass: "title" }, [
        _vm._v("\n    Submit to showcase\n  "),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "info" }, [
        _vm._v("\n    Fields with * are required\n  "),
      ]),
      _vm._v(" "),
      _c("generic-form", {
        ref: "form",
        attrs: {
          submitting: _vm.submitting,
          "initial-links": _vm.links,
          showcase: _vm.showcase,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }