var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    {
      staticClass: "btn primary back-btn",
      attrs: {
        to: _vm.$generateRoute({
          name: "showcase.index",
          params: {
            showcaseId: _vm.showcaseId,
          },
        }),
      },
    },
    [
      _c("icons", { attrs: { name: "chevron" } }),
      _vm._v("\n  " + _vm._s(_vm.buttonText) + "\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }