var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tabs tabs-section",
      attrs: { id: `tabs-${_vm.section.id}`, role: "tablist" },
    },
    [
      _vm.showControls && _vm.showLeft
        ? _c("button", {
            staticClass: "prev-tab controls",
            attrs: {
              type: "button",
              title: "Previous group of tabs",
              value: "-1",
            },
            on: {
              click: function ($event) {
                if ($event.target !== $event.currentTarget) return null
                return _vm.indexDeck.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "tabControls",
          staticClass: "tab-controls",
          on: { scroll: _vm.tabArrows },
        },
        [
          _c(
            "div",
            { ref: "tabDeck", staticClass: "tab-deck" },
            [
              _vm._l(_vm.elements, function (element, index) {
                return [
                  _c(
                    "button",
                    {
                      key: index,
                      ref: element.id,
                      refInFor: true,
                      class: { open: _vm.openTab === element.id },
                      attrs: {
                        id: element.id,
                        order: index,
                        type: "button",
                        role: "tab",
                        value: element.data.title,
                        "aria-label": _vm.lowercase(element.data.title),
                        "aria-selected": _vm.openTab === element.id,
                        "aria-controls": element.id + "_panel",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toggleTab(element.id)
                        },
                        keydown: function ($event) {
                          return _vm.keyboardTabNav($event, element.id)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            element.data.title
                              ? element.data.title
                              : "Tab element"
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm._t("tabs_content", function () {
        return [
          _vm._l(_vm.elements, function (element, index) {
            return [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: element.data.title && _vm.openTab === element.id,
                      expression:
                        "element.data.title && openTab === element.id",
                    },
                  ],
                  key: element.id,
                  staticClass: "tab",
                  class: _vm.tabClass(index),
                  attrs: {
                    id: element.id + "_panel",
                    role: "tabpanel",
                    tabindex: "0",
                    "aria-labelledby": element.id,
                    "aria-expanded":
                      element.data.title && _vm.openTab === element.id,
                  },
                },
                [
                  _vm._l(element.rows, function (row, i) {
                    return [
                      _c(
                        "div",
                        {
                          key: i,
                          staticClass: "row",
                          class: _vm.$rowClass(row),
                        },
                        [
                          _vm._l(row.cols, function (c) {
                            return [
                              _c(
                                "div",
                                { key: c.id, staticClass: "col" },
                                [
                                  _vm._l(c.modules, function (mod) {
                                    return [
                                      _c(mod.name, {
                                        key: mod.data.id,
                                        tag: "component",
                                        attrs: {
                                          id: mod.data.id,
                                          "module-data": mod.data,
                                          "element-id": element.id,
                                        },
                                      }),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]
          }),
        ]
      }),
      _vm._v(" "),
      _vm.showControls && _vm.showRight
        ? _c("button", {
            staticClass: "next-tab controls",
            attrs: { type: "button", title: "Next group of tabs", value: "1" },
            on: {
              click: function ($event) {
                if ($event.target !== $event.currentTarget) return null
                return _vm.indexDeck.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }