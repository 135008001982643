var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "showcase-nav-buttons-container" }, [
    _vm.displayPrev
      ? _c(
          "a",
          {
            staticClass: "link-button btn secondary prev",
            attrs: { href: "" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.prev.apply(null, arguments)
              },
            },
          },
          [
            _c("Icon", { attrs: { id: "ic_chevron_left" } }),
            _vm._v(" "),
            _c("span", [_vm._v("Previous Project")]),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.displayNext
      ? _c(
          "a",
          {
            staticClass: "link-button btn secondary next",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.next.apply(null, arguments)
              },
            },
          },
          [
            _c("span", [_vm._v("Next Project")]),
            _vm._v(" "),
            _c("Icon", { attrs: { id: "ic_chevron_right" } }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }