var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "module video-module" },
    [
      _c("module-controls", {
        attrs: { placeholder: _vm.moduleLabel },
        on: {
          "on-edit": _vm.$openModal,
          "on-remove": _vm.$deleteContentModuleConfirm,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "module_content" }, [
        _vm.ctaContentType === "uploaded"
          ? _c(
              "div",
              [
                !_vm.url
                  ? _c("div", { staticClass: "empty" }, [
                      _vm._v("\n        No video\n      "),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.url
                  ? [
                      _c(
                        "figure",
                        [
                          _c(
                            "p",
                            {
                              staticClass: "offscreen",
                              attrs: { id: _vm.videoDescId },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.desc) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.player.modal && _vm.poster
                            ? [
                                _c(
                                  "button",
                                  {
                                    staticClass: "play poster-btn",
                                    attrs: {
                                      type: "button",
                                      title: "Play this video in a modal",
                                      role: "presentation",
                                      "data-state": "play",
                                      "aria-label": "Play",
                                      tabindex: "-1",
                                      "aria-describedby": _vm.videoDescId,
                                    },
                                    on: { click: _vm.showVideoModal },
                                  },
                                  [
                                    _c("icon", {
                                      staticClass: "play-icon",
                                      attrs: { id: "ic_play_circle_filled" },
                                    }),
                                    _vm._v(" "),
                                    _c("img", {
                                      staticClass: "video-poster",
                                      attrs: { src: _vm.poster, alt: _vm.alt },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _c("video-player", {
                                  attrs: { "module-data": _vm.moduleDataProps },
                                }),
                              ],
                          _vm._v(" "),
                          _vm.caption
                            ? _c("figcaption", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.caption) +
                                    "\n          "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            )
          : _vm.ctaContentType === "embeded"
          ? _c("div", [
              !_vm.ctaContentVideoEmbedCode
                ? _c("div", { staticClass: "empty" }, [
                    _vm._v("\n        No video\n      "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.ctaContentVideoEmbedCode
                ? _c("div", {
                    staticClass: "preview embedded",
                    domProps: {
                      innerHTML: _vm._s(_vm.ctaContentVideoEmbedCode),
                    },
                  })
                : _vm._e(),
            ])
          : _c("div", [
              _c("div", { staticClass: "empty" }, [
                _vm._v("\n        No video\n      "),
              ]),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }