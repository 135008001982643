var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "showcase-nav-buttons-container" }, [
    _c("div", [
      _vm.displayPrev
        ? _c(
            "a",
            {
              staticClass: "link-button prev action-item",
              attrs: { href: "" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.prev.apply(null, arguments)
                },
              },
            },
            [
              _c("icon", { attrs: { id: "ic_chevron_left" } }),
              _vm._v(" "),
              _c("span", { staticClass: "label" }, [
                _vm._v("Previous Project"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.displayNext
        ? _c(
            "a",
            {
              staticClass: "link-button next action-item",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.next.apply(null, arguments)
                },
              },
            },
            [
              _c("span", { staticClass: "label" }, [_vm._v("Next Project")]),
              _vm._v(" "),
              _c("icon", { attrs: { id: "ic_chevron_right" } }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }