var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showcase
    ? _c("generic-form", {
        ref: "form",
        attrs: { submitting: _vm.submitting, "initial-links": _vm.links },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }