
// TODO: Figure out a better way to handle simple cases where we just want to change copy. Add 'showcase' theme options?
import Base from '@core/plugins/Showcase/core/components/client-showcase-slider'

export default {
  name: 'ClientShowcaseSlider',
  extends: Base,
  data () {
    return {
      ctaButtonText: 'View project'
    }
  },
  methods: {
    playVideo (src) {
      this.intervalOverride = 0
      this.$store.dispatch('Global/setModal', {
        type: 'ClientVideoModal',
        data: {
          ctaContentType: 'uploaded',
          ctaContentVideoControls: '',
          videoOptions: {
            url: src,
            player: {
              controls: true,
              autoplay: true
            }
          }
        }
      })
    }
  }
}
