/**
 * icon types = 'default', 'rounded', 'outline', 'sharp'
 *
 */
module.exports = {
  theme: 'clockwork',
  config: {
    show_utility_bar: false
  },
  icons: {
    type: 'default',
    custom: {
      ic_arrow_forward: {
        viewBox: '0 0 24 24',
        content: '<path d="M12.5,21.5c-0.4,0-0.7-0.1-0.9-0.4c-0.5-0.5-0.5-1.3,0-2l5.7-5.7H3.3c-0.8,0-1.3-0.7-1.3-1.3 c0-0.7,0.7-1.3,1.3-1.3h13.9l-5.7-5.8c-0.5-0.5-0.5-1.3,0-2c0.5-0.5,1.3-0.5,2,0l8.1,8.1c0.5,0.5,0.5,1.3,0,2l-8.1,8.1 C13.1,21.3,12.9,21.5,12.5,21.5z"/>'
      },
      ic_play_circle_filled: {
        viewbox: '0 0 120 120',
        content: '<path d="M66.4299717,45.7166195 L83.3652102,73.942017 C84.5018028,75.836338 83.8875426,78.2933791 81.9932215,79.4299717 C81.3715599,79.8029687 80.6602143,80 79.9352385,80 L46.0647615,80 C43.8556225,80 42.0647615,78.209139 42.0647615,76 C42.0647615,75.2750242 42.2617928,74.5636786 42.6347898,73.942017 L59.5700283,45.7166195 C60.7066209,43.8222984 63.163662,43.2080382 65.057983,44.3446308 C65.6209814,44.6824299 66.0921727,45.1536211 66.4299717,45.7166195 Z" transform="rotate(90 63 60)"/>'
      },
      ic_search: {
        viewBox: '0 0 24 24',
        content: '<path d="M3.3,22c-0.3,0-0.7-0.1-0.9-0.4c-0.5-0.5-0.5-1.3,0-1.9l6.4-6.4C8,12.2,7.4,10.7,7.4,9.3c0-4,3.3-7.3,7.3-7.3 S22,5.3,22,9.3s-3.3,7.3-7.3,7.3c-1.6,0-2.9-0.4-4.1-1.3l-6.4,6.4C4,21.9,3.6,22,3.3,22z M14.7,4.6c-2.6,0-4.6,2.1-4.6,4.6 s2.1,4.6,4.6,4.6s4.6-2.1,4.6-4.6S17.2,4.6,14.7,4.6z"/>'
      }
    }
  },
}