var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "FormRoot",
    {
      class: _vm._isAdminArea ? "admin" : "",
      attrs: {
        "form-id": "showcase-submission",
        "enable-submit": !_vm.submitting,
        "submit-button-text": _vm.submitBtnText,
        "show-save-button": !_vm._isAdminArea,
        "cancel-button-text": "Cancel & Discard",
        "disable-validation-on-cancel": true,
      },
    },
    [
      _c("div", { staticClass: "contact-info-wrapper form-block" }, [
        _c("h6", [_vm._v("\n      Contact information*\n    ")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "responsive-flex" },
          [
            _c(
              "div",
              { staticClass: "responsive-margin-right" },
              [
                _c("TextInput", {
                  ref: "firstName",
                  attrs: {
                    model: _vm.formData.first_name,
                    label: "First name",
                    required: "true",
                    validate: "true",
                    "hide-asterisk": true,
                  },
                  on: {
                    "update:model": function ($event) {
                      return _vm.$set(_vm.formData, "first_name", $event)
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("TextInput", {
              ref: "lastName",
              attrs: {
                model: _vm.formData.last_name,
                label: "Last name",
                required: "true",
                validate: "true",
                "hide-asterisk": true,
              },
              on: {
                "update:model": function ($event) {
                  return _vm.$set(_vm.formData, "last_name", $event)
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "responsive-flex" },
          [
            _c(
              "div",
              { staticClass: "responsive-margin-right" },
              [
                _c("TextInput", {
                  ref: "email",
                  attrs: {
                    model: _vm.formData.email_address,
                    label: "Email",
                    required: "true",
                    validate: "true",
                    "hide-asterisk": true,
                  },
                  on: {
                    "update:model": function ($event) {
                      return _vm.$set(_vm.formData, "email_address", $event)
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("SelectInput", {
              ref: "region",
              attrs: {
                label: "In what region did this run?",
                list: _vm.regionsList,
                model: _vm.formData.region,
                required: "true",
                validate: "true",
                "hide-asterisk": true,
              },
              on: {
                "update:model": function ($event) {
                  return _vm.$set(_vm.formData, "region", $event)
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "responsive-flex" },
          [
            _c(
              "div",
              { staticClass: "responsive-margin-right" },
              [
                _c("TextInput", {
                  ref: "business",
                  attrs: {
                    model: _vm.formData.business,
                    label: "What business did this represent?",
                    required: "true",
                    validate: "true",
                    "hide-asterisk": true,
                  },
                  on: {
                    "update:model": function ($event) {
                      return _vm.$set(_vm.formData, "business", $event)
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("SelectInput", {
              ref: "country",
              attrs: {
                label: "In what country did this run?",
                list: _vm.countriesList,
                model: _vm.formData.country,
                required: "true",
                validate: "true",
                "hide-asterisk": true,
              },
              on: {
                "update:model": function ($event) {
                  return _vm.$set(_vm.formData, "country", $event)
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "creative-team-wrapper form-block" },
        [
          _c("h6", [_vm._v("\n      Creative team*\n    ")]),
          _vm._v(" "),
          _c("p", { staticClass: "info" }, [
            _vm._v("\n      Include team members and their roles.\n    "),
          ]),
          _vm._v(" "),
          _vm._l(_vm.formData.data.teamMembers, function (teamMember, index) {
            return _c(
              "div",
              { key: index, staticClass: "row row_25_25_25_25" },
              [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("TextInput", {
                      ref: "teamFirstName",
                      refInFor: true,
                      attrs: {
                        model: _vm.formData.data.teamMembers[index].firstName,
                        label: "First name",
                        required: "true",
                        validate: "true",
                        "hide-asterisk": true,
                      },
                      on: {
                        "update:model": function ($event) {
                          return _vm.$set(
                            _vm.formData.data.teamMembers[index],
                            "firstName",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("TextInput", {
                      ref: "teamLastName",
                      refInFor: true,
                      attrs: {
                        model: _vm.formData.data.teamMembers[index].lastName,
                        label: "Last name",
                        required: "true",
                        validate: "true",
                        "hide-asterisk": true,
                      },
                      on: {
                        "update:model": function ($event) {
                          return _vm.$set(
                            _vm.formData.data.teamMembers[index],
                            "lastName",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("TextInput", {
                      ref: "teamProjectRole",
                      refInFor: true,
                      attrs: {
                        model: _vm.formData.data.teamMembers[index].projectRole,
                        label: "Role on project",
                        required: "true",
                        validate: "true",
                        "hide-asterisk": true,
                      },
                      on: {
                        "update:model": function ($event) {
                          return _vm.$set(
                            _vm.formData.data.teamMembers[index],
                            "projectRole",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col remove-col" },
                  [
                    _vm.formData.data.teamMembers.length > 1
                      ? _c("showcase-button", {
                          attrs: {
                            type: "remove",
                            "button-text": "Remove person",
                          },
                          on: {
                            clickEvent: function ($event) {
                              return _vm.removePerson(index)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "add-row" },
            [
              _c("showcase-button", {
                attrs: { type: "add", "button-text": "Add another person" },
                on: { clickEvent: _vm.addPerson },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-block" }, [
        _c("h6", [_vm._v("Means of communication*")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "responsive-3-columns h-180" },
          [
            _c("CheckboxArrayInput", {
              ref: "meansOfCommunication",
              attrs: {
                name: "means_of_communication",
                model: _vm.meansOfCommunication,
                list: _vm.meansOfCommunicationList,
                required: "true",
                validate: "true",
              },
              on: {
                "update:model": function ($event) {
                  _vm.meansOfCommunication = $event
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-block" }, [
        _c("h6", [_vm._v("Business/Product*")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "responsive-3-columns h-170" },
          [
            _c("CheckboxArrayInput", {
              ref: "businessProduct",
              attrs: {
                name: "business_product",
                model: _vm.businessProduct,
                list: _vm.businessProductList,
                required: "true",
                validate: "true",
              },
              on: {
                "update:model": function ($event) {
                  _vm.businessProduct = $event
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-block" }, [
        _c("h6", [_vm._v("Target audience*")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "responsive-3-columns h-170" },
          [
            _c("CheckboxArrayInput", {
              ref: "targetAudience",
              attrs: {
                name: "target_audience",
                model: _vm.targetAudience,
                list: _vm.targetAudienceList,
                required: "true",
                validate: "true",
              },
              on: {
                "update:model": function ($event) {
                  _vm.targetAudience = $event
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-block dw-50" },
        [
          _c("h6", { staticClass: "mb-0" }, [
            _vm._v("\n      Project title*\n    "),
          ]),
          _vm._v(" "),
          _c("TextInput", {
            ref: "title",
            staticClass: "character-count",
            attrs: {
              model: _vm.formData.title,
              required: "true",
              validate: "true",
              "hide-asterisk": true,
              "aria-label": "Project title",
              maxlength: _vm.maxTitleChars,
            },
            on: {
              "update:model": function ($event) {
                return _vm.$set(_vm.formData, "title", $event)
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "text-right" }, [
            _c("small", [
              _vm._v(_vm._s(_vm.titleCharsLeft) + " characters left"),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-block dw-50" },
        [
          _c("h6", { staticClass: "mb-0" }, [
            _vm._v("\n      Project synopsis*\n    "),
          ]),
          _vm._v(" "),
          _c("TextareaInput", {
            ref: "synopsis",
            staticClass: "character-count",
            attrs: {
              placeholder:
                "Tell us about the project, including your business objective.",
              model: _vm.formData.description,
              required: "true",
              validate: "true",
              maxlength: _vm.maxDescriptionChars,
              rows: 7,
            },
            on: {
              "update:model": function ($event) {
                return _vm.$set(_vm.formData, "description", $event)
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "text-right" }, [
            _c("small", [
              _vm._v(_vm._s(_vm.descriptionCharsLeft) + " characters left"),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-block dw-50" },
        [
          _c("h6", { staticClass: "mb-0" }, [
            _vm._v("\n      Project insights*\n    "),
          ]),
          _vm._v(" "),
          _c("TextareaInput", {
            ref: "insights",
            staticClass: "character-count",
            attrs: {
              placeholder: "Describe the strategic and customer insights.",
              model: _vm.formData.data.insights,
              required: "true",
              validate: "true",
              rows: 7,
              maxlength: _vm.maxImpactChars,
            },
            on: {
              "update:model": function ($event) {
                return _vm.$set(_vm.formData.data, "insights", $event)
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "text-right" }, [
            _c("small", [
              _vm._v(_vm._s(_vm.insightsCharsLeft) + " characters left"),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-block start-date-wrapper" }, [
        _c("h6", { staticClass: "mb-0" }, [
          _vm._v("\n      Start date*\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "responsive-flex" },
          [
            _c("SelectInput", {
              ref: "startMonth",
              staticClass: "responsive-margin-right",
              attrs: {
                label: "Month",
                list: _vm.monthsList,
                model: _vm.formData.data.startMonth,
                required: "true",
                validate: "true",
                "hide-asterisk": true,
              },
              on: {
                "update:model": function ($event) {
                  return _vm.$set(_vm.formData.data, "startMonth", $event)
                },
              },
            }),
            _vm._v(" "),
            _c("SelectInput", {
              ref: "startYeah",
              staticClass: "responsive-margin-right",
              attrs: {
                label: "Year",
                list: _vm.yearsList,
                model: _vm.formData.data.startYear,
                required: "true",
                validate: "true",
                "hide-asterisk": true,
              },
              on: {
                "update:model": function ($event) {
                  return _vm.$set(_vm.formData.data, "startYear", $event)
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-block" },
        [
          _c("h6", [
            _vm._v("\n      Results, learnings, and related links\n    "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "info" }, [
            _vm._v(
              "\n      Provide any supporting or relevant references.\n    "
            ),
          ]),
          _vm._v(" "),
          _c("showcase-links", {
            ref: "showcaseLinks",
            attrs: {
              links: _vm.links,
              placeholder_text: "Link label",
              placeholder_url: "URL",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-block" },
        [
          _c("h6", [_vm._v("\n      Upload project brief (optional)\n    ")]),
          _vm._v(" "),
          (_vm._isAdminArea ? false : true)
            ? _c("div", { staticClass: "has-project-brief" }, [
                _c("p", [
                  _vm._v("\n        Do You Have a Project Brief?\n      "),
                ]),
                _vm._v(" "),
                _c("fieldset", { attrs: { id: "hasProjectBrief" } }, [
                  _c("label", [_vm._v("Yes")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.hasProjectBriefChecked,
                        expression: "hasProjectBriefChecked",
                      },
                    ],
                    staticClass: "hasProjectBriefField",
                    attrs: { type: "radio", name: "hasProjectBrief" },
                    domProps: {
                      value: true,
                      checked: _vm._q(_vm.hasProjectBriefChecked, true),
                    },
                    on: {
                      change: function ($event) {
                        _vm.hasProjectBriefChecked = true
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", [_vm._v("No")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.hasProjectBriefChecked,
                        expression: "hasProjectBriefChecked",
                      },
                    ],
                    staticClass: "hasProjectBriefField",
                    attrs: { type: "radio", name: "hasProjectBrief" },
                    domProps: {
                      value: false,
                      checked: _vm._q(_vm.hasProjectBriefChecked, false),
                    },
                    on: {
                      change: function ($event) {
                        _vm.hasProjectBriefChecked = false
                      },
                    },
                  }),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          (_vm._isAdminArea ? _vm.briefFiles : _vm.hasProjectBriefChecked)
            ? _c("showcase-file-upload", {
                ref: "fileUpload1",
                attrs: {
                  "upload-button-text": "Add files",
                  "upload-files-demo": "uploadFilesDemo",
                  "initial-file-hash-collection": _vm._isAdminArea
                    ? _vm.briefFiles
                    : null,
                },
                on: { "send-hash-collection": _vm.uploadBriefFiles },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-block" },
        [
          _c("h6", [
            _vm._v(
              "\n      Upload related images and videos (up to 10)*\n    "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "info dw-50" }, [
            _vm._v(
              "\n      Upload JPG or PNG photo(s) that are less than 5MB each. The first photo uploaded will be your featured photo.\n      Please use a 16:9 ratio (1128px wide by 632px tall). Videos must be submitted in MP4 format. File names should\n      not contain any spaces or special characters.\n    "
            ),
          ]),
          _vm._v(" "),
          _c("showcase-file-upload", {
            ref: "fileUpload2",
            attrs: {
              "upload-button-text": "Add files",
              "upload-files-demo": "uploadFilesDemo",
              "initial-file-hash-collection": _vm._isAdminArea
                ? _vm.files
                : null,
            },
            on: { "send-hash-collection": _vm.uploadFiles },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }