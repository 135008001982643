var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      class: [
        "add-remove-btn",
        `${_vm.type}-btn`,
        _vm._isAdminArea ? "default" : "",
      ],
      attrs: { type: "button" },
      on: { click: _vm.clickEvent },
    },
    [
      !_vm._isAdminArea ? _c("icon", { attrs: { id: _vm.iconId } }) : _vm._e(),
      _vm._v("\n  " + _vm._s(_vm.buttonText) + "\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }